import {
    FETCH_USER,
    FETCH_ERROR
} from '../actions/actionTypes';

const initialState = {};


function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload;
    case FETCH_ERROR:
        throw new Error('Unable to make request');
    
    default:
      return state;
  }
}

export default userReducer;