import React, { useEffect, useState  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import Header from '../comps/Header';
import { checkAnswers, countPositive, countAnswersByCode, countScores } from './ege/utils';
import { getAllVariantsAnswers } from '../actions';
import { ALL_VARIANTS } from '../utils/consts';
import CodeBarChart from '../comps/CodeBarChart';


import { Panel, Group, Div, Button } from '@vkontakte/vkui';



const Stats = ({ id, go, setActiveVariant }) => {

    const [solvedVariants, setVariants] = useState([]);
    const [configs, setConfigs] = useState([]);

    const dispatch = useDispatch();

    const answers = useSelector(state => state.answers);

    const goToVariant = (e) => {
        setActiveVariant(e.currentTarget.dataset.variant);
        go(e);
    }

    const simpleStats = () => {
        const results = solvedVariants.map(variant => {
            return {
                alltasks: configs[variant].tasks.length,
                answ: countPositive(checkAnswers(configs[variant].tasks, answers[variant])),
                raw: getRawScores(configs[variant], answers[variant])
            }
        }).reduce((acc, curr) => ({ alltasks: acc.alltasks + curr.alltasks, answ: acc.answ + curr.answ, raw: acc.raw + curr.raw }), { alltasks: 0, answ: 0, raw: 0})
        //console.log('results', results);
        return (<div style={{ border: '1px solid #447bba', width: '97%', padding: '10px', borderRadius: '10px 15px 15px 10px' }}
                >{`Решено вариантов: ${solvedVariants.length} из ${ALL_VARIANTS.length}`}<br/>
                 {`Успешно решено заданий ${results.answ} из ${results.alltasks}`}<br/>
                 {`Сырых баллов набрано (в сумме): ${results.raw}`}
        </div>)
    }

    const getRawScores = (config, answers) => {
        const anws = checkAnswers(config.tasks, answers);
        const score = countScores(config.tasks, anws);
        return score;
    }


    useEffect(async () => {
        let solvedVariants = await bridge.send('VKWebAppStorageGetKeys', { count: 50, offset: 0 });
        setVariants(solvedVariants.keys);
        dispatch(getAllVariantsAnswers(ALL_VARIANTS));
        const links = solvedVariants.keys.map(key => `/vars/${key}/${key}.json`);
        axios.all(links.map(link => axios.get(link)))
            .then(axios.spread(function (...res) {
                // all requests are now complete
                let configs = {};
                res.forEach(r => {
                    configs[r.data.id] = r.data;
                });
                setConfigs(configs);
            }));

    }, []) // TO DO: make custom hook

    const renderVariants = () => {
        if (!solvedVariants.length) {
            return <span>ни одного варианта не решено</span>;
        } else {
            return solvedVariants.map(key => (<span key={key} style={{ margin: '10px', display: 'inline-block' }}><Button size="l" appearance="accent" mode="secondary" onClick={goToVariant} data-variant={key} data-to="result">{key}</Button></span>))
        }
    }

    const countByCode = () => {
        const results = solvedVariants.map(variant => {
            return countAnswersByCode(configs[variant].tasks, answers[variant]);
        });
        //console.log('results', results);
        let final = { all: {}, solved: {} };
        for (let i = 0; i < results.length; i++) {
            for (let key of Object.keys(results[i].all)) {
                //console.log('key', key);
                if (!final.all[key]) final.all[key] = 0;
                final.all[key] += results[i].all[key]
            }
            for (let key of Object.keys(results[i].solved)) {
                //console.log('key', key);
                if (!final.solved[key]) final.solved[key] = 0;
                final.solved[key] += results[i].solved[key]
            }
        }
        //console.log('final', final);
        return final;
    }


    return (
        <Panel id={id}>
            <Header go={go} to="home" text="Тренажер ЕГЭ по английскому языку" />
           
            <Group>
                <Div>
                    Решенные варианты: { renderVariants() }
                </Div>
            </Group>
            <Group>
                <Div>
                    {solvedVariants.length && Object.keys(configs).length && Object.keys(answers).length && simpleStats()}
                </Div>
                <Div>
                <Div>
                    { solvedVariants.length && Object.keys(configs).length && Object.keys(answers).length && (<CodeBarChart answCodes={countByCode()} />) }
                </Div>
                </Div>
            </Group>
        </Panel>);
}

Stats.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	
};

export default Stats;
