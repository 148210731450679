import React from 'react';
import PropTypes from 'prop-types';
import InputTable from '../comps/InputTable';
import Test from '../comps/Test';
import List from '../comps/List';

import { Group, Div } from '@vkontakte/vkui';

const SimpleText = ({ task, saveAnswer, answer, disableInput }) => {

    
    const onChange = (table) => {
        saveAnswer({ input: table });
    }

    const inputChange = (e) => {
        saveAnswer({ input: e.target.value });
    }

    const renderInput = (inputType) => {
        switch(inputType) {
            case 'table':
                return <InputTable disableInput={disableInput} onChange={onChange} table={task.answer} answer={answer} />

            case 'test':
                return <Test disableInput={disableInput} values={task.input.q.split('/n')} onChange={onChange} answer={answer} />;

            case 'input':
                return (<input
                    name="input"
                    autocomplete="off"
                    style={{ width: '155px', display: 'block', margin: '0 auto' }}
                    type="text"
                    value={(typeof answer.input === 'string' && answer.input) || ""}
                    maxLength={18}
                    onChange={inputChange}
                    disabled={disableInput}
                />);

            case 'textarea':
                return (<>
                    <div style={{ marginBottom: '15px' }}><List text ={task.input.q} /></div>
                    <textarea 
                        style={{ margin: '0 auto', display: 'block', padding: '5px', resize: 'none' }} 
                        onChange={inputChange} 
                        rows="12" cols="75"
                        value={(typeof answer.input === 'string' && answer.input) || ""}
                        disabled={disableInput}
                    />
                </>);
        }
    }

   
    return (
        <Group>
            <Div>
                <i>{task.instruction}</i>
            </Div>
            <Div style={{ textJustify: "center" }}>
                {
                  task.body.title && <h4 style={{ width: '100%', textAlign: 'center' }}>{task.body.title}</h4>
                }
                { task.body.txt && <List style={{ textIndent: '25px', margin: '1px', textAlign: 'justify' }} text={task.body.txt}/> }
            </Div>
            <Div>
                {
                  task.body.pic1 && (<img style={{ margin: '15px auto', display: 'block' }} src={task.body.pic1} />)
                }
                { task.body.txt2 && <List text={task.body.txt2}/> }
                {
                  task.body.pic2 && (<img style={{ margin: '15px auto', display: 'block' }} src={task.body.pic2} />)
                }
                { task.body.txt3 && <List text={task.body.txt3}/> }
            </Div>
            <Div>
                <List text={task.body.q} />
            </Div>
            <Div>
                { renderInput(task.input.type) }
            </Div>
        </Group>);
}

SimpleText.propTypes = {
	task: PropTypes.object.isRequired
};

export default SimpleText;
