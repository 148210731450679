import React from 'react';
import { Group, Div, CellButton } from '@vkontakte/vkui';
import List from './List';


export default function Solution ({ taskNumber, explain, solve, showSolution }) {

 
  return (
    <>
        {
            showSolution && <Group><Div><List text={explain} /></Div></Group>
        }
        {
            taskNumber > -1 && !showSolution && (<Group><CellButton centered onClick={solve}>
                Решение
            </CellButton></Group>)
        }
    </>
  );
}