import React from 'react';


export default function InputTable({ table, answer, onChange, disableInput }) {


  const inputChange = (e) => {
    const newInput = Object.assign({}, answer.input)
    newInput[e.target.name] = e.target.value;
    onChange(newInput);
  }

  const renderHeader = (table) => {
    return Object.keys(table).map(key => <th key={key}>{key}</th>);
  }

  const renderRow = (table) => {
    return Object.keys(table).map(key => {
      return (
        <td key={key}>
          <input
              name={key}
              autocomplete="off"
              style={{ width: '25px' }}
              type="text"
              maxLength="1"
              value={answer.input[key]  || ''}
              onChange={inputChange}
              disabled={disableInput}
          />
        </td>
      );
    });
  }

  return (
      <div>
       <table style={{ margin: '0 auto' }}>
        <thead>
          <tr>
            { renderHeader(table) }
          </tr>
        </thead>
        <tbody>
          <tr>
            { renderRow(table) }
          </tr>
        </tbody>
      </table>
      </div>
    );
}