import React from 'react';
import PropTypes from 'prop-types';
import ReactAudioPlayer from 'react-audio-player';
import InputTable from '../comps/InputTable';
import Test from '../comps/Test';
import List from '../comps/List';

import { Group, Div } from '@vkontakte/vkui';

const SimpleAudio = ({ task, saveAnswer, answer, disableInput }) => {
    
    const onChange = (table) => {
        saveAnswer({ input: table });
    }

    const inputChange = (e) => {
        saveAnswer({ input: e.target.value });
    }

    const renderInput = (inputType) => {
        switch(inputType) {
            case 'table':
                return <InputTable disableInput={disableInput} onChange={onChange} table={task.answer} answer={answer} />

            case 'test':
                return <Test disableInput={disableInput} values={task.input.q.split('/n')} onChange={onChange} answer={answer} />;

            case 'textarea': //TO DO: component TextArea
                return (<>
                        <div style={{ marginBottom: '15px' }}><List text ={task.input.q} /></div>
                        <textarea 
                            style={{ margin: '0 auto', display: 'block', padding: '5px', resize: 'none' }} 
                            onChange={inputChange} 
                            rows="12" cols="75"
                            value={(typeof answer.input === 'string' && answer.input) || ""}
                            disabled={disableInput}
                        />
                    </>);
        }
    }

   
    return (
        <Group>
            <Div>
                <i>{task.instruction}</i>
            </Div>
            <Div>
                Воспользуйтесь плеером, чтобы прослушать запись.
            </Div>
            <Div>
                <ReactAudioPlayer
                    src={task.body.file}
                    controls
                />
            </Div>
            <Div>
                {task.body.txt.split('/n').map((txt, i) => <span key={i}>{txt}<br/></span>)}
            </Div>
            <Div>
                { renderInput(task.input.type) }
            </Div>
        </Group>);
}

SimpleAudio.propTypes = {
	task: PropTypes.object.isRequired
};

export default SimpleAudio;
