import React, { useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { Button } from '@vkontakte/vkui';
import { Icon16Pause, Icon16Play } from '@vkontakte/icons';


export default function Timer({ expiryTimestamp, expire, style }) {
    const {
      seconds,
      minutes,
      hours,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({ expiryTimestamp, onExpire: () => { 
        console.warn('onExpire called'); 
        expire(); 
    } });

    useEffect(() => {
        console.log('Timer started...')
        start();
    }, []);

  
    return (
      <div style={style}>
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>&nbsp;&nbsp;&nbsp;
        <Button onClick={ isRunning ? pause : resume}>{ isRunning ? <Icon16Pause/> : <Icon16Play/>}</Button>
      </div>
    );
  }