import React from 'react';
import { PanelHeader, PanelHeaderBack, } from '@vkontakte/vkui';


export default function Header({ go, to, text }) {

 
  return (
    <PanelHeader
        left={<PanelHeaderBack onClick={go} data-to={to} />}
    >
       {text}
    </PanelHeader>
    );
}