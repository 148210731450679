import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getVariantAnswers } from '../actions';
import { checkAnswers, countPositive, countAnswersByCode, countScores } from './ege/utils';
import scoresTable from '../utils/procentiles.json';
//import CodeChart from '../comps/CodeChart';
import CodeBarChart from '../comps/CodeBarChart';


import { Panel, PanelHeader, PanelHeaderBack, Group, Div } from '@vkontakte/vkui';

const Result = ({ id, go, user, variantId }) => {

    const [config, setConfig] = useState(null);

    const dispatch = useDispatch();

    const answers = useSelector(state => state.answers[variantId]);

    useEffect(() => {
        axios.get(`/vars/${variantId}/${variantId}.json`).then(resp => {
            setConfig(resp.data);
        });

        if (!answers) dispatch(getVariantAnswers(variantId));
    }, [])

    const simpleStats = () => {
        const anws = checkAnswers(config.tasks, answers);
        const solved = countPositive(anws);
        const raw = getRawScores();
        const score = getPercentileScores(raw);
        return (<div style={{ border: '1px solid #447bba', width: '70%', padding: '10px', borderRadius: '10px 15px 15px 10px' }}
                >{`Успешно решено заданий ${solved} из ${config.tasks.length}`}<br/>
                 {`Сырых баллов набрано ${raw}`}<br/>
                 {`Вторичных баллов набрано ${score || "0"}`}
        </div>)
    }

    const getRawScores = () => {
        const anws = checkAnswers(config.tasks, answers);
        const score = countScores(config.tasks, anws);
        return score;
    }

    const getPercentileScores = (rawScores) => {
        // https://dzodzo.ru/perevod-ballov-ege-po-anglijskomu-pervichnye-i-vtorichnye-bally/
        const percentile = scoresTable[String(rawScores)];
        console.log('percentile', percentile);
        return percentile;
    }

    const getColor = (score) => {
        if (!score) score = 0;
        if (score < scoresTable.red[1]) {
            return "red";
        }
        if (scoresTable.red[1] <= score && score < scoresTable.green[0] ) {
            return 'rgb(68, 123, 186)';
        }
        if (score >= scoresTable.green[0]) {
            return 'green';
        }
    }

    const score = config && answers && getPercentileScores(getRawScores());
    
    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={go} data-to="home"/>}
            >
                {`Вариант № ${variantId}` }
            </PanelHeader>
            <Group>
                <Div>
                    <div style={{ color: 'white', fontSize: '65px', lineHeight: '120px', verticalAlign: 'middle', textAlign: 'center', margin: '30px', height: '120px', width: '120px',  backgroundColor: getColor(score), borderRadius: '50%', float: 'right' }}>
                        { score || "0" }
                    </div>
                </Div>
                <Div>
                    { config && answers && simpleStats() }
                </Div>
                <Div>
                    { config && answers && (<CodeBarChart answCodes={countAnswersByCode(config.tasks, answers)} />) }
                </Div>
            </Group>

        </Panel>);
}

Result.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	
};

export default Result;
