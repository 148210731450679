import React from 'react';

import SimpleAudio from './SimpleAudio';
import SimpleText from './SimpleText';


export default function Task ({ taskNumber, config, answers, saveAnswer, disableInput }) {



    const renderTask = () => {
        if(taskNumber > -1) {
            switch(config.tasks[taskNumber].body.type) {
                case 'audio':
                    return (
                        <SimpleAudio 
                            task={config.tasks[taskNumber]} 
                            id={config.tasks[taskNumber].id} 
                            index={taskNumber}
                            saveAnswer={saveAnswer}
                            answer={answers[taskNumber] || { input: {} }}
                            disableInput={disableInput}
                        />
                    );

                case 'txt':
                    return (
                        <SimpleText 
                            task={config.tasks[taskNumber]} 
                            id={config.tasks[taskNumber].id} 
                            index={taskNumber}
                            saveAnswer={saveAnswer}
                            answer={answers[taskNumber] || { input: {} }}
                            disableInput={disableInput}
                        />
                    );

                default:
                    throw new Error("Uknown type of task!!!");
            }
        }
        return null;
    }

 
  return (
    <>
        { renderTask() }
    </>
  );
}