import React from 'react';
import { Div, Group, Button } from '@vkontakte/vkui';
import List from './List';


export default function Instruction({ instruction, showInstruction, start }) {

 
  return (
      <>
       { showInstruction && (<Group>
            <Div>
                <List style={{ textIndent: '25px', display: 'inline-block', textAlign: 'justify' }} text={instruction} />
            </Div>
            <Div>
                <Button stretched size="l" mode="secondary" onClick={start}>
                  Начать
                </Button>
            </Div>
            <div style={{ height: '350px', width: '100%'}}></div>
        </Group>)
        }
      </>
    );
}