import React from 'react';
import { Group, Div, Button } from '@vkontakte/vkui';


export default function ButtonGroup ({ taskNumber, toPrev, toNext, toResult, tasksLength }) {


 
  return taskNumber > -1 ? (
    <Group>
       <Div style={ { height: '40px' } }>
           { taskNumber > 0 && (<Button size="l" mode="secondary" onClick={toPrev} data-to="prev">
               Предыдущее
              </Button>)
           }
           { taskNumber < tasksLength - 1 && (<Button style={{ float: 'right'}} size="l" mode="secondary" onClick={toNext} data-to="prev">
               Следующее
             </Button>)
           }
           { taskNumber === tasksLength - 1 && (<Button style={{ float: 'right'}} size="l" mode="secondary" appearance="negative" onClick={toResult} data-to="result">
               Результат
             </Button>)
           }
       </Div>
    </Group>) : null;
}