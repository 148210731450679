import React, { useEffect } from 'react';
//import { countAnswersByCode } from './ege/utils';
import codificator from '../utils/codificator.json';
import ProgressBar from './ProgressBar';
  
const CodeBarChart = ({ answCodes }) => {
    
    const codeStats = () => {
        return Object.keys(codificator).map(code => {
            let progress = !answCodes.solved[code] ? 0.5 : calcPercent(answCodes.solved[code], answCodes.all[code]);
             
            return (<div><span style={{ fontSize: '11px',fontStyle: 'italic' }}
                key={code}>{`${code} ${codificator[code]}  ${answCodes.solved[code] || 0} из ${answCodes.all[code] || 0}`}</span>
                <ProgressBar bgcolor="#447bba" progress={progress} height="10px" />
                </div>)
            });
        
    }

    const calcPercent = (solved, all) => {
        if (solved && all) {
            return Math.round((solved/all) * 100);
        } else {
            return 1;
        }
    }
    
        
    return (
        <div style={{ margin: '25px' }}>
        { answCodes && codeStats() }
        </div>
        )
}
  
export default CodeBarChart;

