import { combineReducers } from 'redux';
import { SET_LOADER, FETCH_ERROR } from '../actions/actionTypes';

import user from './user';
import answers from './answers';
import auth from './auth';

function loader(state = false, action) {
    switch (action.type) {
      case SET_LOADER:
        return action.payload;
      
      default:
        return state;
    }
  }

function error(state = null, action) {
    switch (action.type) {
        case FETCH_ERROR:
          return action.payload;
        
        default:
          return state;
      }
}
  

export default combineReducers({
   user,
   answers,
   auth,
   loader,
   error
});
