import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import bridge from '@vkontakte/vk-bridge';
import { View, useAdaptivity, ScreenSpinner, AdaptivityProvider, PanelHeader, AppRoot, ConfigProvider, SplitLayout, SplitCol, ViewWidth } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import { fetchUser, getAuthToken } from './actions';

import Home from './panels/Home';
import Ege from './panels/ege';
import Stats from './panels/Stats';
import Table from './panels/Table';
import Result from './panels/Result';
import Variants from './panels/Variants';



const App = () => {
	const [scheme, setScheme] = useState('bright_light');
	const [activePanel, setActivePanel] = useState('home');
	const [activeVariant, setActiveVariant] = useState(null);

	const { viewWidth } = useAdaptivity();

	const dispatch = useDispatch();

	const fetchedUser = useSelector(state => state.user);
	const showLoader = useSelector(state => state.showLoader);
	const error = useSelector(state => state.error);


	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			console.log('subscribe', type, data);
			if (type === 'VKWebAppUpdateConfig') {
				setScheme(data.scheme)
			}
		});

		dispatch(fetchUser());
		dispatch(getAuthToken());
	}, []);

	const go = (e, panelName) => {
		const name = (e && e.currentTarget.dataset.to) || panelName;
		console.log('go', name, e);
		setActivePanel(name);
	};

	return (
			<ConfigProvider scheme={scheme}>
				<AdaptivityProvider>
					<AppRoot mode="full">
						<SplitLayout header={<PanelHeader separator={false} />} popout={showLoader ? <ScreenSpinner size='large' /> : null}>
							<SplitCol spaced={viewWidth && viewWidth > ViewWidth.MOBILE}>
								<View style={{ minHeight: '700px'}} unselectable="on" activePanel={activePanel}>
									<Home id='home' user={fetchedUser} go={go} />
									<Ege id='ege' go={go} user={fetchedUser} variantId={activeVariant} />
									<Stats id='stats' user={fetchedUser} go={go} setActiveVariant={setActiveVariant} />
									<Table id='table' user={fetchedUser} go={go} />
									<Result id='result' user={fetchedUser} go={go} variantId={activeVariant} />
									<Variants id='variants' go={go} setActiveVariant={setActiveVariant} />
								</View>
							</SplitCol>
						</SplitLayout>
					</AppRoot>
				</AdaptivityProvider>
			</ConfigProvider>
	);
}

export default App;
