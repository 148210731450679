import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { ALL_VARIANTS } from '../utils/consts';
import { getAllVariantsAnswers } from '../actions';
import Header from '../comps/Header';

import { Panel, Button, Group, Div } from '@vkontakte/vkui';





const Variants = ({ id, go, setActiveVariant }) => {

    const dispatch = useDispatch();

    const answers = useSelector(state => state.answers);

    console.log('Variants answers', answers);

    useEffect(() => {
		dispatch(getAllVariantsAnswers(ALL_VARIANTS));
	}, []);

    const setVariant = (e) => {
        setActiveVariant(e.currentTarget.dataset.variant);
        go(e);
    }

    const renderVariants = () => {
        return ALL_VARIANTS.map(key => (
            <Div>
            <Button
                key={key}
                style={{ margin: '5px'}} 
                size="l" 
                appearance={answers[key] ? "neutral" : "accent" }
                mode={answers[key] ? "secondary" : "outline"} 
                onClick={setVariant}
                data-variant={key}
                data-to={ answers[key] ? "result" : "ege"}
            >{key}</Button></Div> ))
    }


    return (
        <Panel style={{ height: '500px' }} id={id}>
            <Header go={go} to="home" text="Тренажер ЕГЭ по английскому языку" />
            <Group>
                <Div style={{ textAlign: 'center'}}>
                    { answers && renderVariants() }
                </Div>
                <Div>
                    <div style={{ height: '250px', width: '100%'}}></div>
                </Div>
            </Group>

        </Panel>
    );
}

Variants.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	
};

export default Variants;