import React, { useState, useEffect } from 'react';
import { RadioGroup, Radio } from '@vkontakte/vkui';


export default function Test({ values, onChange, answer, disableInput }) {

 // const [ input, setInput ] = useState(null);
  
  const renderValues = () => {
    return values.map((v, i) => {
        return (
            <Radio disabled={disableInput} key={i} onChange={handleInput} name={i + 1} value={i + 1} checked={answer.input[0] === String(i + 1)}>
            {v}
            </Radio>
        );
    })
  }

  const handleInput = (e) => {
    console.log(e.target.value);
    //setInput(e.target.value);
    onChange([e.target.value]);
  }

  return (
      <div>
        <RadioGroup>
            { renderValues() }
        </RadioGroup>
      </div>
    );
}