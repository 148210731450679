import React from 'react';



export default function List({ text, style }) {

  return (
      <>
        {text?.split('/n').map((txt, i) => <p style={style} key={i}>{txt}</p>)}
      </>
    );
}