import React from 'react';
import PropTypes from 'prop-types';
import Header from '../comps/Header';

import { Panel, SimpleCell, Group, Cell, Div, Avatar } from '@vkontakte/vkui';

const t_style = {
	border: '1px solid #ddd', 
	padding: '3px',
	textAlign: 'center'
}

const Table = ({ id, go, fetchedUser }) => (
	<Panel id={id}>
		<Header go={go} to="home" text="Тренажер ЕГЭ по английскому языку" />
		<Group>
            <Div>
				<table style={{ margin: '0 auto', borderCollapse: 'collapse', width: '100%' }}>
					<thead>
						<tr>
							<th style={t_style}>Пользователь</th>
							<th style={t_style}>Решено вариантов</th>
							<th style={t_style}>Решено заданий</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style={t_style}>
								<SimpleCell
									subtitle="VKontakte"
									before={<Avatar src="https://sun9-80.userapi.com/c1219/u12680198/d_b431b96f.jpg" />}
								>
									Дмитрий Малюгин
								</SimpleCell>
							</td>
							<td style={t_style}>3</td>
							<td style={t_style}>16</td>
						</tr>
						<tr>
							<td style={t_style}><SimpleCell
									subtitle="VKontakte"
									before={<Avatar src="https://sun4.userapi.com/sun4-10/s/v1/ig2/wY8TwWS-9bftHiugfgo59xUun9wm7QA36oNeBpoNMSzBRSICM75no6osKS4BzTrhCEV65LIAnseDMZOQ8suFcfxm.jpg?size=50x50&quality=95&crop=456,691,921,921&ava=1" />}
								>
									Алевтина Александрова
								</SimpleCell></td>
							<td style={t_style}>2</td>
							<td style={t_style}>11</td>
						</tr>
					</tbody>
				</table>
            </Div>
		</Group>

	</Panel>
);

Table.propTypes = {
	id: PropTypes.string.isRequired,
	go: PropTypes.func.isRequired,
	
};

export default Table;
